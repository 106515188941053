import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: "rw9fpkkz",
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: "skQZfGA8Ffe0RndKRtbXjnADX4UlgiVQPfpOwXlnn7sSWmEeDi0Q4zwrUVfBeUwuCoS4tOrg1kaiBDUsuREHnrNwrLbX9iyGQiLXOBHzd0ivG4kBcy8hZqibXQzoHVl5MFmEO6rKl0rMJ7Nve2l5Mgg7OqT9BKqw8GvdsnOt1opM6SOUy2kz",
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
