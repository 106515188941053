import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <a href="https://www.linkedin.com/in/abdullahrasheed-/" target="_blank" rel="noopener noreferrer">
      <div>
        <FaLinkedinIn />
      </div>
    </a>
    <a href="https://twitter.com/Abdulla91622421" target="_blank" rel="noopener noreferrer">
      <div>
        <BsTwitter />
      </div>
    </a>
    <a href="https://www.facebook.com/profile.php?id=100009675009600" target="_blank" rel="noopener noreferrer">
      <div>
        <FaFacebookF />
      </div>
    </a>
    <a href="https://www.instagram.com/abdullahrasheeddd/?next=%2F" target="_blank" rel="noopener noreferrer">
      <div>
        <BsInstagram />
      </div>
    </a>
  </div>
);

export default SocialMedia;
